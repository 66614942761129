<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <app-card-code
        :title="`${$t('menu.nomenclador')} / ${$t('menu.hotels')} / ${$t('menu.tipoCamas')}`"
      >
        <TipoCamasList />
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import TipoCamasList from './tablas/TipoCamasList.vue'

export default {
  components: {
    AppCardCode,
    TipoCamasList,
  },
}
</script>
